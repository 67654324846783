.custom-radio, .custom-checkbox, .custom-checkbox-2 {
  margin-top: 50px;
}

.custom-page {
  font-family: "Open Sans", "Segoe WP", "Segoe UI", Helvetica, Arial, sans-serif;
  color: #7F8C9A;
  background-image: url("../images/background_2.jpg");
  position: relative;
  height: 100vh;
  object-fit: cover;

  h1 {
    font-family: $font-title;
    font-size: 50px;
    color: $font-white;
  }

  h2 {
    font-family: $font-title;
    font-size: 28px;
    color: $font-black;
  }

  .card-stat {
    border-radius: 15px;
    background-color: $color-ray-du-cul;
    border: none;

    img {
      width: 180px;
      height: 180px;
      margin: 20px;
    }

    .border-blue {
      border: 10px solid $color-blue;
    }

    .border-red {
      border: 10px solid $color-red;
    }

    .card-img-top {
      border-radius: 20px;
      background-color: $font-white;
    }

    .card-body {
      margin-top: 1rem;
    }

    .svg-inline--fa {
      margin-bottom: 0;
    }
  }

  .card-player {
    //top: -20px;
    max-width: 200px;
    max-height: 350px;
  }

  .btn-custom_char {
    padding: 2% 8%;
    background-color: $color-red;
    border: 1px solid $color-red;

    &:hover {
      background-color: $color-blue;
      border: 1px solid $color-blue;
      color: $font-white;
    }
  }

  .form-check,
  .hospit {
    padding-top: 20px;
  }
}

.card-player {
  background-color: $color-ray-du-cul;
  border: none; //2px solid $font-black;

  .card-img-top {
    background-color: $font-white;
    //border: 2px solid $font-black;
  }

  .card-footer {
    border: none;
    background-color: transparent;
  }
}

.custom-checkbox {

  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
  }

  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 75px;
    cursor: pointer;
  }

  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before,
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    content: '';
    position: absolute;
  }

  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    left: 0;
    top: -3px;
    width: 65px;
    height: 30px;
    background: $color-red;
    border-radius: 15px;
    -webkit-transition: background-color .2s;
    -moz-transition: background-color .2s;
    -ms-transition: background-color .2s;
    transition: background-color .2s;
  }

  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    width: 20px;
    height: 20px;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -ms-transition: all .2s;
    transition: all .2s;
    border-radius: 50%;
    background: $font-white;
    top: 2px;
    left: 5px;
  }

  /* on checked */
  [type="checkbox"]:checked + label:before {
    background: #777777;
  }

  [type="checkbox"]:checked + label:after {
    background: $color-blue;
    top: 2px;
    left: 40px;
  }

  [type="checkbox"]:not(:checked) + label .ui:before,
  [type="checkbox"]:checked + label .ui:after {
    position: absolute;
    left: 6px;
    width: 65px;
    border-radius: 15px;
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -ms-transition: all .2s;
    transition: all .2s;
  }

  [type="checkbox"]:not(:checked) + label .ui:before {
    content: "Non";
    left: 28px;
    color: $font-white;
  }

  [type="checkbox"]:checked + label .ui:after {
    content: "Oui";
    color: $font-white;
    left: 10px;
  }

  [type="checkbox"]:focus + label:before {
    border: 1px dashed #777;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: -1px;
  }
}


/* style des boutons radios pour l'age */
.custom-radio {

  /* Cachons la case à cocher */
  [type="radio"]:not(:checked),
  [type="radio"]:checked {
    position: absolute;
    display: none;
    left: -9999px;
  }

  /* on prépare le label */
  [type="radio"]:not(:checked) + label,
  [type="radio"]:checked + label {
    position: relative; /* permet de positionner les pseudo-éléments */
    padding-left: 25px; /* fait un peu d'espace pour notre case à venir */
    cursor: pointer; /* affiche un curseur adapté */
  }

  /* Aspect des checkboxes */
  /* :before sert à créer la case à cocher */
  [type="radio"]:not(:checked) + label:before,
  [type="radio"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 17px;
    height: 17px; /* dim. de la case */
    border: 1px solid $color-blue;
    background: transparent;
    border-radius: 3px; /* angles arrondis */
    box-shadow: inset 0 1px 3px rgba($color-blue, .3) /* légère ombre interne */
  }

  /* Aspect général de la coche */
  [type="radio"]:not(:checked) + label:after,
  [type="radio"]:checked + label:after {
    content: '✔';
    position: absolute;
    top: -35px;
    left: -1px;
    font-size: 50px;
    color: $color-red;
    transition: all .2s; /* on prévoit une animation */
  }

  /* Aspect si "pas cochée" */
  [type="radio"]:not(:checked) + label:after {
    opacity: 0; /* coche invisible */
    transform: scale(0); /* mise à l'échelle à 0 */
  }

  /* Aspect si "cochée" */
  [type="radio"]:checked + label:after {
    opacity: 1; /* coche opaque */
    transform: scale(1); /* mise à l'échelle 1:1 */
  }

  /* aspect désactivée */
  [type="radio"]:disabled:not(:checked) + label:before,
  [type="radio"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
  }

  /* styles de la coche (si cochée/désactivée) */
  [type="radio"]:disabled:checked + label:after {
    color: #999;
  }

  /* on style aussi le label quand désactivé */
  [type="radio"]:disabled + label {
    color: #aaa;
  }

  /* aspect au focus de l'élément */
  [type="radio"]:checked:focus + label:before,
  [type="radio"]:not(:checked):focus + label:before {
    border: 1px dotted blue;
  }
}


.gender-radio {
  /* Cachons la case à cocher */
  [type="radio"]:not(:checked),
  [type="radio"]:checked {
    position: absolute;
    left: -9999px;
  }

  p, label, svg, .btn {
    margin: 2px;
    color: $color-red;

    &:hover {
      color: $color-blue;
    }
  }

  .fa-4x {
    font-size: 3em;
  }

}


.custom-checkbox-2 {
  [class*="entypo-"]:before {
    font-family: 'entypo', sans-serif;
  }

  .buka {
    text-align: center;
    font-weight: bold;
    letter-spacing: 1px;
    color: #fff;
    opacity: 0;
    -webkit-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
  }

  .buka a {
    color: #6fcbed;
    text-decoration: none;
  }

  #check:checked ~ .buka {
    opacity: 1;
    letter-spacing: 2px;
  }

  input[type="checkbox"] {
    display: none;
  }

  //#loadcheck {
  //  position: absolute;
  //  left: 0;
  //  right: 0;
  //  margin: 30px auto;
  //  margin-top: 0;
  //}

  #loadcheck1 {
    //position: absolute;
    left: 0;
    right: 0;
    //margin: 75px auto;
  }

  .loadcheck {
    width: 165px;
    height: 40px;
    font-size: 35px;
  }

  .loadcheck span {
    float: left;
  }

  .load {
    display: block;
    width: 7px;
    height: 7px;
    margin: 20px 5px;
    border-radius: 10px;
    transition: .5s;
    cursor: pointer;
  }

  .load:nth-child(2) {
    background: #db1536;
  }

  .load:nth-child(3) {
    background: rgba(219, 21, 54, .7);
  }

  .load:nth-child(4) {
    background: rgba(219, 21, 54, .5);
  }

  .load:nth-child(5) {
    background: rgba(219, 21, 54, .3);
  }

  .load:nth-child(6) {
    background: rgba(219, 21, 54, .1);
  }

  span[class*="entypo"] {
    cursor: pointer;
  }

  span[class*="cancel"] {
    font-size: 40px;
    color: #db1536;
    transition: .5s;
    transition-delay: .1s;
  }

  span[class*="check"] {
    color: rgba(0, 0, 0, .1);
    transition: .5s;
    transition-delay: .1s;
  }

  #check:checked + .loadcheck .entypo-check {
    color: #58d37b;
  }

  #check:checked + .loadcheck .entypo-cancel {
    color: rgba(0, 0, 0, .1);
  }

  #check:checked + .loadcheck .load:nth-child(2) {
    background: rgba(88, 211, 123, .1);
  }

  #check:checked + .loadcheck .load:nth-child(3) {
    background: rgba(88, 211, 123, .3);
  }

  #check:checked + .loadcheck .load:nth-child(4) {
    background: rgba(88, 211, 123, .5);
  }

  #check:checked + .loadcheck .load:nth-child(5) {
    background: rgba(88, 211, 123, .7);
  }

  #check:checked + .loadcheck .load:nth-child(6) {
    background: #58d37b;
  }

  #check1:checked + .loadcheck .entypo-check {
    color: #58d37b;
  }

  #check1:checked + .loadcheck .entypo-cancel {
    color: rgba(0, 0, 0, .1);
  }

  #check1:checked + .loadcheck .load:nth-child(2) {
    background: rgba(88, 211, 123, .1);
  }

  #check1:checked + .loadcheck .load:nth-child(3) {
    background: rgba(88, 211, 123, .3);
  }

  #check1:checked + .loadcheck .load:nth-child(4) {
    background: rgba(88, 211, 123, .5);
  }

  #check1:checked + .loadcheck .load:nth-child(5) {
    background: rgba(88, 211, 123, .7);
  }

  #check1:checked + .loadcheck .load:nth-child(6) {
    background: #58d37b;
  }
}